import React from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './Documents.css'; // Import the CSS for styling

const Documents = () => {


  return (
    <div className="doc-container">
      <DashboardHeader />
      <Sidebar />
      <div className="doc-main-content">
        <div className="doc-content-area">
          

        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Documents;
