import React from 'react';
import './SignIn_Footer.css';

const SignInFooter = () => {
    return (
        <div className="signin-footer">
            © 2024 TrackMyDocs. All rights reserved.
        </div>
    );
}

export default SignInFooter;
