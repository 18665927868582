import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardHeader from './DashboardHeader';
import Sidebar from './Sidebar';
import DashboardFooter from './DashboardFooter';
import './Invoices.css';

const Invoices = () => {
  const { case_number } = useParams(); // Get the case number from the URL
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoice_date: '',
    invoice_number: '',
    property_address: '',
    our_ref: '',
    client: '',
    vat: 0.2, // Default VAT rate
  });
  const [invoiceType, setInvoiceType] = useState(''); // State for invoice type dropdown
  const [charges, setCharges] = useState(''); // State for charges
  const [vatApplicable, setVatApplicable] = useState(true); // State for VAT toggle
  const navigate = useNavigate();  // Use the navigate hook

  const invoiceTypeChargesMap = useMemo(() => ({
    'S21 NCC': 80.00,
    'S21 Notice': 120.00,
    'S48 Notice': 70.00,
    'S8 Notice - Rent Arrears': 120.00,
    'S8 Notice - RA - AOG': 140.00,
    'S21 - S8 - Notice': 140.00,
    'S8 - 21 Notice - RA - AOG': 160.00,
    'NTQ': 125.00,
    'CNTQ': 150.00,
    'S3 Notice': 125.00,
    'S6 Notice': 125.00,
    'S13 Notice': 125.00,
    'S25 Notice': 250.00,
    'S146 Notice': 250.00,
    'CLTG': 125.00,
  }), []); // Empty array as the dependencies

  const [invoices, setInvoices] = useState([]); // New state to store the array of invoices

  // State to control dropdown visibility
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdownToggle = () => {
    setShowDropdown(prevState => !prevState);
  };
  // Inside your component
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);  // Close the dropdown if clicking outside
    }
  };

  //Handler to click outside 
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

    // Fetch invoices when the page loads or case number changes
    useEffect(() => {
      const fetchInvoicesForCase = async () => {
        try {
          const token = localStorage.getItem('access_token');
          const response = await axios.get(`/api/invoices/gen-invoice/${case_number}/`, {
            headers: { 'Authorization': `Bearer ${token}` },
          });
  
          if (response.status === 200 && response.data.length > 0) {
            const sortedInvoices = response.data.sort((a, b) => parseInt(a.invoice_number) - parseInt(b.invoice_number));
            setInvoices(sortedInvoices);  // Populate dropdown with invoice numbers
          } else {
            console.error('Failed to fetch invoices:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching invoices:', error);
          alert('Error fetching invoices. Please try again.');
        }
      };
  
      fetchInvoicesForCase();  // Fetch invoices on page load or case number change
    }, [case_number]);

  // Handle invoice selection
  const handleInvoiceSelection = async (invoice_number, is_new_invoice) => {
    setInvoiceDetails(prevDetails => ({
      ...prevDetails,
      is_new_invoice: is_new_invoice  // Add this line to set whether the invoice is new or existing
    }));

    if (!is_new_invoice) {
      // Fetch the selected invoice details
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`/api/invoices/gen-invoice/${case_number}/${invoice_number}/`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (response.status === 200) {
          const invoiceData = response.data;
          setInvoiceDetails({
            invoice_date: invoiceData.invoice_date,
            invoice_number: invoiceData.invoice_number,
            property_address: invoiceData.property_address,
            our_ref: invoiceData.our_ref,
            client: invoiceData.client,
            vat: invoiceData.vat,
            is_new_invoice: false  // Ensure to set this to false when an existing invoice is fetched
          });
        } else {
          console.error('Failed to fetch invoice details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching invoice details:', error);
        alert('Error fetching invoice details. Please try again.');
      }
    } else {
      // Fetch the next available invoice number and default values from the backend
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`/api/invoices/new-invoice/${case_number}/`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (response.status === 200) {
          const newInvoiceData = response.data;
          // Extract number from the format "New Invoice (8905)"
          const match = newInvoiceData.invoice_number.match(/\((\d+)\)/);
          const onlyNumber = match ? match[1] : 'New Invoice'; // Fallback to 'New Invoice' if regex fails
          setInvoiceDetails({
            invoice_date: newInvoiceData.invoice_date || '',
            invoice_number: onlyNumber,
            property_address: newInvoiceData.property_address || '',
            our_ref: newInvoiceData.our_ref || '',
            client: newInvoiceData.client || '',
            vat: newInvoiceData.vat || 0.2,
            is_new_invoice: true  // Set to true as this is a new invoice
          });
        } else {
          console.error('Failed to fetch new invoice details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching new invoice details:', error);
        alert('Error fetching new invoice details. Please try again.');
      }
    }
  };



  useEffect(() => {
    // Update charges when invoice type changes
    if (invoiceType && invoiceTypeChargesMap[invoiceType]) {
      setCharges(invoiceTypeChargesMap[invoiceType].toFixed(2));
    }
  }, [invoiceType, invoiceTypeChargesMap]);

  const handleChargesChange = (e) => {
    setCharges(e.target.value);
  };

  const handleVatToggle = () => {
    setVatApplicable(!vatApplicable);
  };

  const calculateVat = () => {
    const vatAmount = vatApplicable ? (parseFloat(charges) * invoiceDetails.vat).toFixed(2) : '0.00';
    return vatAmount;
  };

  const calculateTotal = () => {
    const vatAmount = parseFloat(calculateVat());
    const total = parseFloat(charges) + vatAmount;
    return total.toFixed(2);
  };

 // Handler to generate invoice
  const handleGenerateInvoice = async (event) => {
    event.preventDefault();

    // Immediately log when the function is called
    console.log("handleGenerateInvoice called");

    const token = localStorage.getItem('access_token');
    if (!token) {
      console.error('No token available for the request!');
      alert('You are not authenticated. Please log in again.');
      return;
    }

    // Directly use the state that indicates if a new invoice is needed
    const forceNewInvoice = invoiceDetails.is_new_invoice;
    // Logging to check the current state of 'is_new_invoice'
    console.log("Is new invoice selected:", forceNewInvoice);

    if (forceNewInvoice === false && !invoiceDetails.invoice_number) {
      // No invoice number selected
      alert("Please select an invoice number.");
      return;
    }

    try {
      console.log("Sending request with forceNewInvoice:", forceNewInvoice); // Logging the flag being sent in the request
      const response = await fetch('https://app.trackmydocs.cloud/api/invoices/generate-invoice/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          case_number: case_number,
          invoice_date: invoiceDetails.invoice_date,
          invoice_number: invoiceDetails.invoice_number,  // Existing or new invoice number
          property_address: invoiceDetails.property_address,
          our_ref: invoiceDetails.our_ref,
          client: invoiceDetails.client,
          invoice_type: invoiceType,
          charges: parseFloat(charges),
          vat_amount: parseFloat(calculateVat()),
          total_net_amount: parseFloat(charges),
          invoice_total: parseFloat(calculateTotal()),
          vat_no: "458 0158 86",
          force_new_invoice: forceNewInvoice  // Pass the flag to the backend
        })
      });

      if (response.ok) {
        alert('Invoice generated successfully!');
        navigate('/invoice-management');  // Redirect after success
      } else {
        console.error('Failed to generate invoice:', response.statusText);
        alert('Failed to generate invoice.');
      }
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice.');
    }
  };

  return (
    <div className="invoices-page-container">
      <DashboardHeader />
      <Sidebar />
      <div className="invoices-main-content">
        <div className="invoices-detail-documents">
          <h3 className="case-detail-h3">Landlord Advice Fee</h3>
          
          <div className="invoice-details">
            <label>Invoice Number:</label>
            <div className="in-num-dropdown-container" ref={dropdownRef}>
              <button
                id="dropdownDefaultButton"
                className="in-num-dropdown-button"
                type="button"
                onClick={handleDropdownToggle}  // This toggles the dropdown
              >
                {invoiceDetails.invoice_number && !invoiceDetails.is_new_invoice ? invoiceDetails.invoice_number : "Select an Invoice Number"}
                <svg className="w-4 h-4 ml-2" fill="none" viewBox="0 0 20 20">
                  <path d="M5.5 7.5L10 12l4.5-4.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
              <div className={`in-num-dropdown-content ${showDropdown ? 'show' : ''}`}>
                {invoices.map((invoice, index) => (
                  <button key={index} onClick={() => {
                    handleInvoiceSelection(invoice.invoice_number, invoice.is_new_invoice);  // Handle selection
                    setShowDropdown(false);  // Close dropdown on selection
                  }}>
                    {invoice.invoice_number}
                  </button>
                ))}
                <button onClick={() => {
                  handleInvoiceSelection('', true);  // Create new invoice logic
                  setShowDropdown(false);  // Close dropdown
                }}>
                  Create New Invoice Number
                </button>
              </div>
            </div>

            <label>Invoice Date:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.invoice_date} readOnly />

            <label>VAT No:</label>
            <input type="text" className="invoice-input" value="458 0158 86" readOnly />

            <label>Property Address:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.property_address} readOnly />

            <label>Our Ref:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.our_ref} readOnly />

            <label>Client:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.client} readOnly />

            <label>Invoice Type:</label>
            <select
              value={invoiceType}
              onChange={(e) => setInvoiceType(e.target.value)}
              className="invoice-input"
            >
              <option value="">Select Invoice Type</option>
              <option value="S21 NCC">Section 21 Notice Complaince Check</option>
              <option value="S21 Notice">Section 21 Notice</option>
              <option value="S48 Notice">Section 48 Notice</option>
              <option value="S8 Notice - Rent Arrears">Section 8 Notice - Rent Arrears</option>
              <option value="S8 Notice - RA - AOG">Section 8 Notice - Rent Arrears and or Any Other Grounds</option>
              <option value="S21 - S8 - Notice">Section 8 & 21 Notice</option>
              <option value="S8 - 21 Notice - RA - AOG">Section 8 - Rent Arrears and or Any Other Grounds - & 21 Notice</option>
              <option value="NTQ">Notice to Quit</option>
              <option value="CNTQ">Company Notice to Quit</option>
              <option value="S3 Notice">Section 3 Notice</option>
              <option value="S6 Notice">Section 6 Notice</option>
              <option value="S13 Notice">Section 13 Notice</option>
              <option value="S25 Notice">Section 25 Notice</option>
              <option value="S146 Notice">Section 146 Notice</option>
              <option value="CLTG">Covering Letter to Guarantor</option>            
            </select>

            <label>Charges:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${charges}`} 
              onChange={handleChargesChange} 
            />

            <label className="vat-toggle">
              <input 
                type="checkbox" 
                checked={vatApplicable} 
                onChange={handleVatToggle} 
              />
              Apply VAT
            </label>

            <label>VAT:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${calculateVat()}`} 
              readOnly 
            />

            <label>Total Net Amount:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${charges}`} 
              readOnly 
            />

            <label>Invoice Total:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${calculateTotal()}`} 
              readOnly 
            />
          </div>

          <button 
            className="invoice-generate-btn"
            onClick={handleGenerateInvoice}
          >
            Create Invoice
          </button>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Invoices;
