import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardHeader.css';
import { FaUserCircle } from 'react-icons/fa';
import axios from 'axios';

const DashboardHeader = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  // Update isMobile state when the window is resized
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setDropdownVisible((prevState) => !prevState);
  };

  // Handle signout functionality
  const handleSignout = async () => {
    try {
      // Send signout request to the backend (no response body needed)
      await axios.post('https://app.trackmydocs.cloud/api/signout', {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      });
    } catch (error) {
      console.error('Error during signout:', error);
    } finally {
      // Clear any user session, such as JWT tokens, from localStorage
      localStorage.removeItem('access_token');
      
      // Redirect to the signin page
      navigate('/signin');
    }
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="dashboard-header">
      <div className="header-left">
        <img src="/trackmydocs_favicon.png" alt="Logo" className="dashboard-logo" />
      </div>
      <div className="header-right">
        {isMobile ? (
          <div className="mobile-profile-icon" onClick={toggleDropdown} ref={dropdownRef}>
            <FaUserCircle size={24} />
            {isDropdownVisible && (
              <div className="dropdown-content">
                <button onClick={handleSignout}>Signout</button> {/* Trigger signout on click */}
              </div>
            )}
          </div>
        ) : (
          <div className="profile-icon" onClick={toggleDropdown} ref={dropdownRef}>
            <FaUserCircle size={24} />
            {isDropdownVisible && (
              <div className="dropdown-content">
                <button onClick={handleSignout}>Signout</button> {/* Trigger signout on click */}
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default DashboardHeader;
