import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [cases, setCases] = useState([]);
  const [searchInput, setSearchInput] = useState(''); // State for storing the search input
  const [selectedCases, setSelectedCases] = useState([]); // State to manage selected cases

  //Handler to fetch cases in the table
  useEffect(() => {
    const fetchCases = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No JWT token found, redirecting to login.');
        navigate('/signin');
        return;
      }

      try {
        const response = await axios.get('https://app.trackmydocs.cloud/api/clients/cases/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log('Fetched cases:', response.data); // Add this line
        setCases(response.data);
      } catch (error) {
        console.error('Error fetching cases:', error);
        if (error.response && error.response.status === 401) {
          navigate('/signin');
        }
      }
    };

    fetchCases();
  }, [navigate]); // Ensure fetchCases is called only when navigate changes

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  //Function Handler to manage row click 
  const handleRowClick = (sanitisedCaseNumber) => {
    navigate(`/cases/${sanitisedCaseNumber}`);
  };

  // Function to filter cases based on the search input
  const filteredCases = cases.filter(
    caseItem =>
      caseItem.case_number.toLowerCase().includes(searchInput) ||
      (caseItem.person && caseItem.person.toLowerCase().includes(searchInput))
  );

  // Handle individual row selection
  const handleRowSelect = (caseItem) => {
    setSelectedCases((prevSelected) => {
      if (prevSelected.find((item) => item.id === caseItem.id)) {
        return prevSelected.filter((item) => item.id !== caseItem.id); // Remove the case from selection
      } else {
        return [...prevSelected, { id: caseItem.id, case_number: caseItem.case_number }]; // Add the case (id & case_number) to the selection
      }
    });
  };

  // Handle select all rows
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all visible cases, ensuring IDs are collected
      setSelectedCases(filteredCases.map((caseItem) => ({
        id: caseItem.id, 
        case_number: caseItem.case_number
      })));
    } else {
      setSelectedCases([]); // Clear selection
    }
  };

  // Handle delete action
  const handleDeleteSelected = async () => {
    const token = localStorage.getItem('access_token');
    if (!token || selectedCases.length === 0) {
      console.error('No cases selected or no JWT token found.');
      return;
    }
  
    try {
      // Extract case IDs and case numbers
      const caseNumbers = selectedCases.map((item) => item.case_number);

      console.log("Deleting case numbers:", caseNumbers);
  
      const response = await axios.post('https://app.trackmydocs.cloud/api/clients/case-del-files/', {
        case_numbers: caseNumbers,  // Send selected case numbers
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        // Alert user of successful deletion
        alert("Cases and associated records deleted successfully.");
  
        // Remove the deleted cases from the table
        setCases((prevCases) => prevCases.filter((caseItem) => !caseNumbers.includes(caseItem.case_number)));
        setSelectedCases([]); // Clear selection after deletion
      
        // Optionally, refresh the page or fetch the cases again to update the list
        // window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting cases:', error);
    }
  };

  // Handle archive action
  const handleArchiveSelected = async () => {
    const token = localStorage.getItem('access_token');
    if (!token || selectedCases.length === 0) {
      console.error('No cases selected or no JWT token found.');
      return;
    }

    try {
      // Extract case numbers
      const caseNumbers = selectedCases.map((item) => item.case_number);

      console.log('Archiving case numbers:', caseNumbers);

      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/clients/archive-cases/',
        {
          case_numbers: caseNumbers, // Send selected case numbers
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Alert user of successful archiving
        alert('Cases archived successfully.');

        // Remove the archived cases from the table
        setCases((prevCases) =>
          prevCases.filter((caseItem) => !caseNumbers.includes(caseItem.case_number))
        );
        setSelectedCases([]); // Clear selection after archiving

        // Optionally, refresh the page or fetch the cases again to update the list
        // window.location.reload();
      }
    } catch (error) {
      console.error('Error archiving cases:', error);
      alert('An error occurred while archiving cases. Please try again.');
    }
  };

    

  return (
    <div className="dashboard-container">
      <DashboardHeader />
      <Sidebar />
      <div className="main-content">
        <div className="content-area">
          <div className="recent-cases">
            <div className="recent-cases-header">
              <h2>Recent Cases</h2>
              <input
                type="text"
                className="search-bar"
                placeholder="Search cases..."
                onChange={handleSearchChange}
                value={searchInput}
              />
              <button 
                className="create-case-btn" 
                onClick={() => navigate('/new-client')}
              >
                Create Case
              </button>
              {selectedCases.length > 0 && (
              <>
                <button
                  className="case-row-archive"
                  onClick={handleArchiveSelected}
                >
                  Archive Selected
                </button>
                <button 
                  className="case-row-delete"
                  onClick={handleDeleteSelected}
                >
                  Delete Selected
                </button>
              </>
            )}

            </div>
            <table className="cases-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={selectedCases.length === filteredCases.length && filteredCases.length > 0}
                    />
                  </th>
                  <th colSpan="5">
                    <div className="header-content">
                      <span>Case Number</span>
                      <span>Landlord</span>
                      <span>Client Type</span>
                      <span>Tenant</span>
                      <span>Status</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredCases.length > 0 ? (
                  filteredCases.map((caseItem) => {
                    const sanitisedCaseNumber = caseItem.case_number.replace(/\//g, '-');
                    return (
                      <tr key={caseItem.id}>
                        {/* Checkbox in the first column, does not trigger row click */}  
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedCases.some((item) => item.id === caseItem.id)}
                            onChange={(e) => {
                              e.stopPropagation(); // Ensure checkbox doesn't trigger row click
                              handleRowSelect(caseItem); // Handle row selection
                            }}
                          />
                        </td>
                        {/* Rest of the row triggers the navigation on click */}
                        <td colSpan="5" onClick={() => handleRowClick(sanitisedCaseNumber)}>
                          <div className="row-content">
                            <span>{caseItem.case_number}</span>
                            <span>{caseItem.person}</span>
                            <span>{caseItem.client_type}</span>
                            <span>{caseItem.tenant || '-'}</span>
                            <span>{caseItem.status}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No cases found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Dashboard;
