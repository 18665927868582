import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './InvoiceManagement.css'; // Import the CSS for styling
import { useNavigate } from 'react-router-dom';

const InvoiceManagement = () => {
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState(''); // State for storing the search input
  const [selectedInvoices, setSelectedInvoices] = useState([]); // State to manage selected invoices


  useEffect(() => {
    const fetchInvoices = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No JWT token found, redirecting to login.');
        return;
      }
      try {
        const response = await axios.get('https://app.trackmydocs.cloud/api/invoices/invoice-table/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Sort invoices by invoice number before setting state
        const sortedInvoices = response.data.sort((a, b) => a.invoice_number.localeCompare(b.invoice_number));
        console.log(sortedInvoices); // Check what's being stored in state
        setInvoices(sortedInvoices);
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices(); // Call the function on component mount
  }, []);

  const handleRowClick = (invoiceNumber) => {
    navigate(`/disp-invoice/${invoiceNumber}`);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  // Function to filter invoices based on the search input
  const filteredInvoices = invoices.filter(
    (invoice) =>
      invoice.invoice_number.toLowerCase().includes(searchInput) ||
      (invoice.client && invoice.client.toLowerCase().includes(searchInput))
  );

  // Handle individual invoice selection
  const handleInvoiceSelect = (invoice) => {
    setSelectedInvoices((prevSelected) => {
      if (prevSelected.find((item) => item.invoice_number === invoice.invoice_number)) {
        return prevSelected.filter((item) => item.invoice_number !== invoice.invoice_number); // Deselect
      } else {
        return [...prevSelected, invoice]; // Select
      }
    });
  };

  // Handle select all invoices
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      // Select all filtered invoices
      setSelectedInvoices(filteredInvoices);
    } else {
      // Deselect all
      setSelectedInvoices([]);
    }
  };

  // Handle delete action
  const handleDeleteSelected = async () => {
    const token = localStorage.getItem('access_token');
    if (!token || selectedInvoices.length === 0) {
      console.error('No invoices selected or no JWT token found.');
      return;
    }

    try {
      // Extract invoice numbers
      const invoiceNumbers = selectedInvoices.map((invoice) => invoice.invoice_number);

      console.log('Deleting invoice numbers:', invoiceNumbers);

      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/invoices/del-invoices/',
        {
          invoice_numbers: invoiceNumbers,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        // Alert user of successful deletion
        alert('Invoices and associated records deleted successfully.');

        // Remove the deleted invoices from the table
        setInvoices((prevInvoices) =>
          prevInvoices.filter((invoice) => !invoiceNumbers.includes(invoice.invoice_number))
        );
        setSelectedInvoices([]); // Clear selection after deletion

        // Optionally, refresh the page or fetch the invoices again to update the list
        // window.location.reload();
      }
    } catch (error) {
      console.error('Error deleting invoices:', error);
      alert('An error occurred while deleting invoices. Please try again.');
    }
  };

  return (
    <div className="im-container">
      <DashboardHeader />
      <Sidebar />
      <div className="im-main-content">
        <div className="im-content-area">
          
          {/* Invoice Management Recent Invoices Div */}
          <div className="im-recent-invoices">
            <div className="im-recent-invoices-header">
              <h2>Recent Invoices</h2>
              <input
                type="text"
                className="im-search-bar"
                placeholder="Search invoices..."
                onChange={handleSearchChange}
                value={searchInput}
              />
              {selectedInvoices.length > 0 && (
                <button className="im-delete-btn" onClick={handleDeleteSelected}>
                  Delete Selected
                </button>
              )}
            </div>
            <table className="im-invoices-table">
              <thead>
                <tr>
                  <th>
                    <input
                      type="checkbox"
                      onChange={handleSelectAll}
                      checked={
                        selectedInvoices.length === filteredInvoices.length && filteredInvoices.length > 0
                      }
                    />
                  </th>
                  <th>Invoice Number</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Our Reference</th>
                  <th>Client</th>
                </tr>
              </thead>
              <tbody>
                {filteredInvoices.length > 0 ? (
                  filteredInvoices.map((invoice) => (
                    <tr key={invoice.invoice_number}>
                      <td>
                        <input
                          type="checkbox"
                          checked={selectedInvoices.some(
                            (item) => item.invoice_number === invoice.invoice_number
                          )}
                          onChange={() => handleInvoiceSelect(invoice)}
                        />
                      </td>
                      <td onClick={() => handleRowClick(invoice.invoice_number)}>
                        {invoice.invoice_number}
                      </td>
                      <td onClick={() => handleRowClick(invoice.invoice_number)}>
                        £{invoice.total_amount ? parseFloat(invoice.total_amount).toFixed(2) : '0.00'}
                      </td>
                      <td onClick={() => handleRowClick(invoice.invoice_number)}>{invoice.status}</td>
                      <td onClick={() => handleRowClick(invoice.invoice_number)}>{invoice.our_ref}</td>
                      <td onClick={() => handleRowClick(invoice.invoice_number)}>{invoice.client}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No invoices found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default InvoiceManagement;
