import React, { useState, useEffect } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './NewClient.css'; // Assuming the same styles

const NewClient = () => {
    const initialFormData = {
        clientType: 'Landlord',
        c_title: '',
        given_name: '',  // Updated
        surname: '',
        email: '',
        contact_number: '',  // Updated
        gender: '',
        l_flat: '',  // Added
        l_housename: '',  // Added
        no: '',
        address: '',
        district: '',
        city: '',
        postcode: '',
        t_title: '',
        t_given_name: '',  // Updated
        t_surname: '',
        t_email: '',
        t_contact_number: '',  // Updated
        t_gender: '',
        t_flat: '',  // Added
        t_housename: '',  // Added
        t_no: '',
        t_street: '',
        t_city: '',
        t_postcode: '',
        t_district: '',
        additionalLandlords: [],  // Array to handle multiple additional landlords
        additionalTenants: []  // Array to handle multiple additional tenants
    };



    const [showAddress, setShowAddress] = useState(false);
    const [showTenantDetails, setShowTenantDetails] = useState(false);
    const [showTenantAddress, setShowTenantAddress] = useState(false);
    const [showAdditionalLandlord, setShowAdditionalLandlord] = useState(false);
    const [showAdditionalTenant, setShowAdditionalTenant] = useState(false);

    // Retrieve saved form data from localStorage if available
    //const savedFormData = JSON.parse(localStorage.getItem('formData')) || initialFormData;
    const [formData, setFormData] = useState(initialFormData);
    //const [clientType, setClientType] = useState('Landlord');

    //Landlord States to manage Landlord data
    const [landlords, setLandlords] = useState([]);
    const [landlordPreview, setLandlordPreview] = useState('');
    // Landlord Address State
    const [landlordAddress, setLandlordAddress] = useState({
        l_flat: '',         // Added landlord's flat
        l_housename: '',    // Added landlord's house name
        no: '',
        address: '',
        district: '',
        city: '',
        postcode: '',
    });
    const [additionalLandlordPreview, setAdditionalLandlordPreview] = useState('');
    const [additionalLandlords, setAdditionalLandlords] = useState([]);

    //Tenant States to manage tenant data
    const [tenants, setTenants] = useState([]);
    const [tenantPreview, setTenantPreview] = useState('');
    // Tenant Address State
    const [tenantAddress, setTenantAddress] = useState({
        t_flat: '',         // Added tenant's flat
        t_housename: '',    // Added tenant's house name
        t_no: '',
        t_street: '',
        t_city: '',
        t_postcode: '',
        t_district: ''
    });
    const [additionalTenantPreview, setAdditionalTenantPreview] = useState('');
    const [additionalTenants, setAdditionalTenants] = useState([]);


    // Save form data to localStorage on each change
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);

    //Handler to refresh token every hour
    useEffect(() => {
        // Refresh token every hour
        const refreshInterval = setInterval(() => {
            refreshAuthToken();
        }, 60 * 60 * 1000); // 1 hour interval
    
        return () => clearInterval(refreshInterval); // Cleanup on component unmount
    }, []);    

    // Adjusted handleInputChange to ensure additional landlord preview updates correctly
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith("addland_")) {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
            if (name === 'addland_given_name' && formData.addland_surname) {
                setAdditionalLandlordPreview(value + ' ' + formData.addland_surname);
            } else if (name === 'addland_surname' && formData.addland_given_name) {
                setAdditionalLandlordPreview(formData.addland_given_name + ' ' + value);
            }
        } else if (name.startsWith("addtent_")) {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
            if (name === 'addtent_given_name' && formData.addtent_surname) {
                setAdditionalTenantPreview(value + ' ' + formData.addtent_surname);
            } else if (name === 'addtent_surname' && formData.addtent_given_name) {
                setAdditionalTenantPreview(formData.addtent_given_name + ' ' + value);
            }
        } else {
            setFormData(prev => ({
                ...prev,
                [name]: value
            }));
            if (name === 'given_name' && formData.surname) {
                setLandlordPreview(value + ' ' + formData.surname);
            } else if (name === 'surname' && formData.given_name) {
                setLandlordPreview(formData.given_name + ' ' + value);
            } else if (name === 't_given_name' && formData.t_surname) {
                setTenantPreview(value + ' ' + formData.t_surname);
            } else if (name === 't_surname' && formData.t_given_name) {
                setTenantPreview(formData.t_given_name + ' ' + value);
            }
        }
    };

    const handleSectionToggle = (section) => {
        // Define the default states for all sections
        const defaultVisibility = {
            showAddress: false,
            showTenantDetails: false,
            showTenantAddress: false,
            showAdditionalLandlord: false,
            showAdditionalTenant: false
        };
    
        // Reset all sections
        setShowTenantDetails(defaultVisibility.showTenantDetails);
        setShowTenantAddress(defaultVisibility.showTenantAddress);
        setShowAdditionalTenant(defaultVisibility.showAdditionalTenant);
        setShowAddress(defaultVisibility.showAddress);
        setShowAdditionalLandlord(defaultVisibility.showAdditionalLandlord);
    
        // Enable the requested section
        switch (section) {
          case 'landlord':
            // This case seems redundant as you're already setting all to false
            break;
          case 'additionalLandlord':
            setFormData(prev => ({
              ...prev,
              addland_title: '',
              addland_given_name: '',
              addland_surname: '',
              addland_email: '',
              addland_contact_number: ''
            }));
            setShowAdditionalLandlord(true);
            break;
          case 'landlordAddress':
            setShowAddress(true);
            // Load saved address data into form fields
            setFormData({
                ...formData,
                no: landlordAddress.no,
                address: landlordAddress.address,
                district: landlordAddress.district,
                city: landlordAddress.city,
                postcode: landlordAddress.postcode
            });
            break;
          case 'tenantDetails':
            setShowTenantDetails(true);
            break;
          case 'tenantAddress':
            setShowTenantAddress(true);
            // Load saved tenant address data into form fields
            setFormData(prev => ({
                ...prev,
                t_no: tenantAddress.t_no,
                t_street: tenantAddress.t_street,
                t_city: tenantAddress.t_city,
                t_postcode: tenantAddress.t_postcode,
                t_district: tenantAddress.t_district
            }));
            break;
          case 'additionalTenant':
            setFormData(prev => ({
                ...prev,
                addtent_title: '',
                addtent_given_name: '',
                addtent_surname: '',
                addtent_email: '',
                addtent_contact_number: ''
            }));
            setShowAdditionalTenant(true);
            break;
          default:
            // Handle default case or error
            break;
        }
    };
    
    
    const handleSave = () => {
        if (!landlordPreview) return; // Don't save if there's no name entered
    
        const newLandlordData = {
            clientType: formData.clientType,
            title: formData.c_title,
            given_name: formData.given_name,  // Updated
            surname: formData.surname,
            email: formData.email,
            contact_number: formData.contact_number,  // Updated
            gender: formData.gender,
        };
    
        setLandlords([...landlords, newLandlordData]);
        setLandlordPreview(''); // Clear the preview after saving
    
        console.log("Landlord saved:", newLandlordData);
        // Optionally reset formData here if needed

        // Show success alert
        alert('Landlord added successfully!');
    };

    const handleLoadLandlord = (landlord) => {
        setFormData({
            ...formData,
            clientType: landlord.clientType,
            c_title: landlord.title,
            given_name: landlord.given_name,
            surname: landlord.surname,
            email: landlord.email,
            contact_number: landlord.contact_number,
            gender: landlord.gender,
        });
        // Ensure the form is visible
        setShowAdditionalLandlord(false);
        setShowAddress(false);
        setShowTenantDetails(false);
        setShowTenantAddress(false);
        setShowAdditionalTenant(false);
    };

    const handleSaveAddress = () => {
        // Update landlord address in the state (assuming you have a state to store this, otherwise create one)
        const newAddressData = {
            l_flat: formData.l_flat,  // Added
            l_housename: formData.l_housename,  // Added
            no: formData.no,
            address: formData.address,
            district: formData.district,
            city: formData.city,
            postcode: formData.postcode,
        };
    
        // Log the saved address to the console
        console.log("Address saved:", newAddressData);
    
        // Update the state with the new address data
        // Assuming you have a method or state to update this, otherwise you'll need to set up state management for this.
        setLandlordAddress(newAddressData); // This is an example, adjust based on your actual state management.
    
        // Optionally, you might want to clear the form fields or provide some user feedback

        // Show success alert
        alert('Landlord Address Saved successfully!');
    };
    
    const handleSaveAdditionalLandlord = () => {
        const newAdditionalLandlord = {
            title: formData.addland_title,
            given_name: formData.addland_given_name,
            surname: formData.addland_surname,
            email: formData.addland_email,
            contact_number: formData.addland_contact_number
        };
    
        // Update both the separate state and formData
        setAdditionalLandlords(prev => [...prev, newAdditionalLandlord]);
        console.log("Additional Landlord saved:", newAdditionalLandlord);

        setFormData(prev => ({
            ...prev,
            additionalLandlords: [...prev.additionalLandlords, newAdditionalLandlord],
            // Clear fields after saving
            addland_title: '',
            addland_given_name: '',
            addland_surname: '',
            addland_email: '',
            addland_contact_number: ''
        }));
    
        setAdditionalLandlordPreview(''); // Reset preview
        // Show success alert
        alert('Additional Landlord Saved successfully!');
    };
    

    // Ensure correct form fields display when loading additional landlord details
    const handleLoadAdditionalLandlord = (landlord) => {
        // Reset unrelated form states
        setShowAddress(false);
        setShowTenantDetails(false);
        setShowTenantAddress(false);
        setShowAdditionalTenant(false);
        setFormData({
            ...formData,
            addland_title: landlord.title,
            addland_given_name: landlord.given_name,
            addland_surname: landlord.surname,
            addland_email: landlord.email,
            addland_contact_number: landlord.contact_number
        });
        setShowAdditionalLandlord(true);
    };
    
    // Function to save primary tenant
    const handleSavePrimaryTenant = () => {
        if (!tenantPreview) return; // Don't save if there's no name entered

        const newTenantData = {
            title: formData.t_title,
            given_name: formData.t_given_name,  // Updated
            surname: formData.t_surname,
            email: formData.t_email,
            contact_number: formData.t_contact_number,  // Updated
            gender: formData.t_gender
        };

        setTenants([...tenants, newTenantData]);
        setTenantPreview(''); // Clear the preview after saving
        console.log("Primary Tenant saved:", newTenantData);
        console.log("Updated formData:", formData);

        // Show success alert
        alert('Tenant Saved successfully!');
    };

    // Function to save tenant address
    const handleSaveTenantAddress = () => {
        const newAddressData = {
            t_flat: formData.t_flat,  // Added
            t_housename: formData.t_housename,  // Added
            t_no: formData.t_no,
            t_street: formData.t_street,
            t_city: formData.t_city,
            t_postcode: formData.t_postcode,
            t_district: formData.t_district
        };

        setTenantAddress(newAddressData); // Save tenant address
        console.log("Tenant Address saved:", newAddressData);
        console.log("Updated formData:", formData);
        // Show success alert
        alert('Tenant Address Saved successfully!');
    };

    // Function to save additional tenant
    const handleSaveAdditionalTenant = () => {
        const newAdditionalTenant = {
            title: formData.addtent_title,
            given_name: formData.addtent_given_name,
            surname: formData.addtent_surname,
            email: formData.addtent_email,
            contact_number: formData.addtent_contact_number
        };
    
        // Update both the separate state and formData
        setAdditionalTenants(prev => [...prev, newAdditionalTenant]);
        console.log("Additional Tenant saved:", newAdditionalTenant);
    
        setFormData(prev => ({
            ...prev,
            additionalTenants: [...prev.additionalTenants, newAdditionalTenant],
            // Clear fields after saving
            addtent_title: '',
            addtent_given_name: '',
            addtent_surname: '',
            addtent_email: '',
            addtent_contact_number: ''
        }));
    
        setAdditionalTenantPreview(''); // Reset preview
        // Show success alert
        alert('Additional Tenant Saved successfully!');
    };

    // Function to load primary tenant details into the form
    const handleLoadTenant = (tenant) => {
        setFormData({
            ...formData,
            t_title: tenant.title,
            t_given_name: tenant.given_name,
            t_surname: tenant.surname,
            t_email: tenant.email,
            t_contact_number: tenant.contact_number,
            t_gender: tenant.gender,
            t_no: tenant.no,
            t_street: tenant.street,
            t_city: tenant.city,
            t_postcode: tenant.postcode,
            t_district: tenant.district
        });
        setShowTenantDetails(true);
        setShowTenantAddress(false);
        setShowAdditionalTenant(false);
    };

    // Function to load additional tenant details into the form
    const handleLoadAdditionalTenant = (tenant) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            addtent_title: tenant.title,
            addtent_given_name: tenant.given_name,
            addtent_surname: tenant.surname,
            addtent_email: tenant.email,
            addtent_contact_number: tenant.contact_number  // Ensure consistent field names
        }));
        setShowAdditionalTenant(true);
        setShowTenantDetails(false);
        setShowTenantAddress(false);
    };

    //Function to extend token expiry to 8 hours 
    const decodeTokenExpiration = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
                .join('')
        );
        const decodedToken = JSON.parse(jsonPayload);
        return decodedToken.exp; // Return the expiration time
    };

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        if (storedToken) {
            const tokenExpiration = decodeTokenExpiration(storedToken);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            
            if (tokenExpiration - currentTime > 8 * 60 * 60) { // 8 hours = 28800 seconds
                // Keep the session active or alert user to re-login
                setTimeout(() => {
                    alert('Session is about to expire. Please re-login.');
                    window.location.href = '/signin'; // Redirect to sign page
                }, (tokenExpiration - currentTime - 5) * 1000); // Prompt 5 seconds before expiration
            }
        }
    }, []); // Run once on component mount

    const refreshAuthToken = async () => {
        const refreshToken = localStorage.getItem('refresh_token');
        try {
            const response = await fetch('https://app.trackmydocs.cloud/api/auth/refresh/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ token: refreshToken })
            });
            const data = await response.json();
            if (data.access_token) {
                localStorage.setItem('access_token', data.access_token);
            } else {
                console.log('Token refresh failed');
            }
        } catch (error) {
            console.error('Error refreshing token', error);
        }
    };
    
    useEffect(() => {
        // Refresh token every hour (for example)
        const refreshInterval = setInterval(() => {
            refreshAuthToken();
        }, 60 * 60 * 1000); // 1 hour interval
    
        return () => clearInterval(refreshInterval); // Cleanup on component unmount
    }, []);    

    // Handler Function to create New Case with Landlords & Tenants provided
    const handleSubmit = async (event) => {
        event.preventDefault(); 

        const token = localStorage.getItem('access_token');
        if (!token) {
            console.error('No authentication token available.');
            alert('You are not authenticated. Please log in again.');
            return;
        }

        const primaryLandlord = {
            title: formData.c_title,
            given_name: formData.given_name,  // Updated
            surname: formData.surname,
            email: formData.email,
            contact_number: formData.contact_number,  // Updated
            gender: formData.gender,
            address: `${formData.no} ${formData.address}, ${formData.district}`,
            city: formData.city,
            postcode: formData.postcode,
            flat: formData.l_flat,           // Added field for landlord's flat
            housename: formData.l_housename  // Added field for landlord's house name
        };

        const primaryTenant = {
            title: formData.t_title,
            given_name: formData.t_given_name,  // Updated
            surname: formData.t_surname,
            email: formData.t_email,
            contact_number: formData.t_contact_number,  // Updated
            gender: formData.t_gender,
            property_address: [
                formData.t_flat,
                formData.t_housename,
                formData.t_no,
                formData.t_street,
                formData.t_district,
                formData.t_city,
                formData.t_postcode
            ]
            .filter((field) => field && field.trim() !== '')  // Remove empty or whitespace-only fields
            .join(', ')
        };

        const additionalLandlordsData = formData.additionalLandlords.map(landlord => ({
            title: landlord.title,
            given_name: landlord.given_name,  // Updated
            surname: landlord.surname,
            email: landlord.email,
            contact_number: landlord.contact_number  // Updated
        }));

        const additionalTenantsData = formData.additionalTenants.map(tenant => ({
            title: tenant.title,
            given_name: tenant.given_name,  // Updated
            surname: tenant.surname,
            email: tenant.email,
            contact_number: tenant.contact_number  // Updated
        }));

        const payload = {
            client_type: formData.clientType,
            primary_landlord: primaryLandlord,
            tenant: primaryTenant,
            additional_landlords: additionalLandlordsData,
            additional_tenants: additionalTenantsData
        };

        console.log('Submitting the following data to the backend:', JSON.stringify(payload));

        try {
            const response = await fetch('https://app.trackmydocs.cloud/api/clients/clients/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(payload)
            });
            
            const result = await response.json();
            if (response.ok) {
                alert('Client and additional landlords/tenants created successfully!');
                window.location.href = '/dashboard'; // Redirect to dashboard
            } else {
                alert(`Failed to create client: ${result.detail || 'Please check the provided data.'}`);
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
            alert('An error occurred while submitting the form. Please try again.');
            window.location.href = '/dashboard'; // Redirect to dashboard
        }
    };
    
    return (
        <div className="dashboard-container">
            <DashboardHeader />
            <Sidebar />
            <div className="main-content">
                <div className="content-area">
                    <div className="new-client">
                        <div className="nc-three-columns">                            
                            <div className="nc-add-additional-client">
                                <div className="nc-row">
                                    <h3 className="nc-land-btn" onClick={() => handleSectionToggle('landlord')}>Landlord</h3>
                                    <div className="nc-add-c-button" onClick={() => handleSectionToggle('additionalLandlord')}>+</div>
                                </div>
                                {/* Render the dynamic landlord preview */}
                                {/* Dynamic preview as a clickable item if it contains data */}
                                {landlordPreview && (
                                    <div className="nc-row" onClick={() => handleLoadLandlord({
                                        ...formData, // Load the current form data as if it's a saved landlord
                                    })}>
                                        <p className="nc-dn-btn">{landlordPreview}</p>
                                    </div>
                                )}
                                {landlords.map((landlord, index) => (
                                    <div key={index} className="nc-row" onClick={() => handleLoadLandlord(landlord)}>
                                        <p className="nc-dn-btn">{landlord.given_ame} {landlord.surname}</p>
                                    </div>
                                ))}

                                {additionalLandlordPreview && (
                                    <div className="nc-row" onClick={() => handleLoadAdditionalLandlord({
                                        ...formData, // Load the current form data for additional landlord
                                    })}>
                                        <p className="nc-dn-btn">{additionalLandlordPreview}</p>
                                    </div>
                                )}
                                {additionalLandlords.map((landlord, index) => (
                                    <div key={index} className="nc-row" onClick={() => handleLoadAdditionalLandlord(landlord)}>
                                        <p className="nc-dn-btn">{landlord.given_name} {landlord.surname}</p>
                                    </div>
                                ))}

                                <div className="nc-row">
                                    <h3 className="nc-la-btn" onClick={() => handleSectionToggle('landlordAddress')}>Landlord Address</h3>
                                </div>
                                <div className="nc-row">
                                    <h3 className="nc-ten-btn" onClick={() => handleSectionToggle('tenantDetails')}>Tenant</h3>
                                    <div className="nc-add-t-button" onClick={() => handleSectionToggle('additionalTenant')}>+</div>
                                </div>

                                {/* Render the dynamic tenant preview */}
                                {tenantPreview && (
                                    <div className="nc-row" onClick={() => handleLoadTenant({
                                        ...formData, // Load the current form data as if it's a saved tenant
                                    })}>
                                        <p className="nc-dn-btn">{tenantPreview}</p>
                                    </div>
                                )}
                                {tenants.map((tenant, index) => (
                                    <div key={index} className="nc-row" onClick={() => handleLoadTenant(tenant)}>
                                        <p className="nc-dn-btn">{tenant.given_name} {tenant.surname}</p>
                                    </div>
                                ))}
                                {additionalTenantPreview && (
                                    <div className="nc-row" onClick={() => handleLoadAdditionalTenant({
                                        ...formData, // Load the current form data for additional tenant
                                    })}>
                                        <p className="nc-dn-btn">{additionalTenantPreview}</p>
                                    </div>
                                )}
                                {additionalTenants.map((tenant, index) => (
                                    <div key={index} className="nc-row" onClick={() => handleLoadAdditionalTenant(tenant)}>
                                        <p className="nc-dn-btn">{tenant.given_name} {tenant.surname}</p>
                                    </div>
                                ))}

                                <div className="nc-row">
                                    <h3 className="nc-ta-btn" onClick={() => handleSectionToggle('tenantAddress')}>Tenant Address</h3>
                                </div>
                            </div>
                            <div className="nc-vertical-divider"></div>
                            <div className="nc-form-column">
                                <form className="new-client-form" onSubmit={handleSubmit}>                                  
                                    {!showTenantDetails && !showTenantAddress && !showAdditionalTenant && !showAddress && !showAdditionalLandlord && (
                                        <>
                                            <h3>New Client - Landlord</h3>
                                            <div className="form-group">
                                                <label htmlFor="clientType">Client Type:</label>
                                                <select 
                                                    id="clientType" 
                                                    name="clientType" 
                                                    value={formData.clientType} 
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="Landlord">Landlord</option>
                                                    <option value="Organisation">Organisation</option>
                                                    <option value="Business">Business</option>
                                                    <option value="Trust">Trust</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="c_title">Title:</label>
                                                <select
                                                    id="c_title"
                                                    name="c_title"
                                                    value={formData.c_title}
                                                    onChange={handleInputChange}
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="given_name">Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="given_name" 
                                                    name="given_name" 
                                                    value={formData.given_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="surname">Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="surname" 
                                                    name="surname"
                                                    value={formData.surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="email">Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="email" 
                                                    name="email"
                                                    value={formData.email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="contact_number">Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="contact_number" 
                                                    name="contact_number"
                                                    value={formData.contact_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Gender:</label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="gender" 
                                                        value="Male" 
                                                        checked={formData.gender === 'Male'}
                                                        onChange={handleInputChange}
                                                    /> Male
                                                </label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="gender" 
                                                        value="Female"
                                                        checked={formData.gender === 'Female'}
                                                        onChange={handleInputChange}
                                                    /> Female
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <button className="nc-save-btn" onClick={(e) => { e.preventDefault(); handleSave(); }}>Save</button>
                                            </div>
                                        </>
                                    )}

                                    {showAddress && (
                                            <>
                                            <h3>Landlord Address</h3>
                                            <div className="form-group">
                                                <label htmlFor="l_flat">Flat:</label>
                                                <input 
                                                    type="text" 
                                                    id="l_flat" 
                                                    name="l_flat" 
                                                    value={formData.l_flat}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="l_housename">House Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="l_housename" 
                                                    name="l_housename" 
                                                    value={formData.l_housename}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="No">No:</label>
                                                <input 
                                                    type="text" 
                                                    id="no" 
                                                    name="no" 
                                                    value={formData.no}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="address">Street:</label>
                                                <input 
                                                    type="text" 
                                                    id="address" 
                                                    name="address" 
                                                    value={formData.address}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="district">District:</label>
                                                <input 
                                                    type="text" 
                                                    id="district" 
                                                    name="district" 
                                                    value={formData.district}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="city">Town / City:</label>
                                                <input 
                                                    type="text" 
                                                    id="city" 
                                                    name="city" 
                                                    value={formData.city}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="postcode">Postcode:</label>
                                                <input 
                                                    type="text" 
                                                    id="postcode" 
                                                    name="postcode" 
                                                    value={formData.postcode}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button className="nc-save-btn" onClick={(e) => { e.preventDefault(); handleSaveAddress(); }}>Save</button>
                                            </div>
                                            </>
                                        )}

                                        {showAdditionalLandlord && (
                                            <>
                                            <h3>Additional Landlord</h3>
                                            <div className="form-group">
                                                <label htmlFor="addland_title">Title:</label>
                                                <select 
                                                    id="addland_title" 
                                                    name="addland_title" 
                                                    value={formData.addland_title} 
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_given_name">Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="addland_given_name" 
                                                    name="addland_given_name" 
                                                    value={formData.addland_given_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_surname">Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="addland_surname" 
                                                    name="addland_surname" 
                                                    value={formData.addland_surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_email">Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="addland_email" 
                                                    name="addland_email"
                                                    value={formData.addland_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addland_contact_number">Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="addland_contact_number" 
                                                    name="addland_contact_number"
                                                    value={formData.addland_contact_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button className="nc-save-btn" onClick={(e) => { e.preventDefault(); handleSaveAdditionalLandlord(); }}>Save</button>
                                            </div>
                                            </>
                                        )}

                                        {showTenantDetails && (
                                            <>
                                            <h3>New Tenant</h3>
                                            <div className="form-group">
                                                <label htmlFor="t_title">Tenant Title:</label>
                                                <select 
                                                    id="t_title" 
                                                    name="t_title" 
                                                    value={formData.t_title} 
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_given_name">Tenant Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_given_name" 
                                                    name="t_given_name" 
                                                    value={formData.t_given_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_surname">Tenant Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_surname" 
                                                    name="t_surname" 
                                                    value={formData.t_surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_email">Tenant Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="t_email" 
                                                    name="t_email"
                                                    value={formData.t_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_contact_number">Tenant Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_contact_number" 
                                                    name="t_contact_number"
                                                    value={formData.t_contact_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Tenant Gender:</label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="t_gender" 
                                                        value="Male" 
                                                        checked={formData.t_gender === 'Male'}
                                                        onChange={handleInputChange}
                                                    /> Male
                                                </label>
                                                <label>
                                                    <input 
                                                        type="radio" 
                                                        name="t_gender" 
                                                        value="Female"
                                                        checked={formData.t_gender === 'Female'}
                                                        onChange={handleInputChange}
                                                    /> Female
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <button className="nc-save-btn" onClick={(e) => { e.preventDefault(); handleSavePrimaryTenant(); }}>Save</button>
                                            </div>
                                            </>
                                        )}

                                        {showTenantAddress && (
                                            <>
                                            {/* Tenant address fields */}
                                            <h3>Tenant Address</h3>
                                            <div className="form-group">
                                                <label htmlFor="t_flat">Flat:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_flat" 
                                                    name="t_flat" 
                                                    value={formData.t_flat}
                                                    onChange={handleInputChange}
                                                />
                                            </div>                                            
                                            <div className="form-group">
                                                <label htmlFor="t_housename">House Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_housename" 
                                                    name="t_housename" 
                                                    value={formData.t_housename}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_no">No:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_no" 
                                                    name="t_no" 
                                                    value={formData.t_no}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_street">Street:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_street" 
                                                    name="t_street" 
                                                    value={formData.t_street}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_district">District:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_district" 
                                                    name="t_district" 
                                                    value={formData.t_district}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_city">Town / City:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_city" 
                                                    name="t_city" 
                                                    value={formData.t_city}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="t_postcode">Postcode:</label>
                                                <input 
                                                    type="text" 
                                                    id="t_postcode" 
                                                    name="t_postcode" 
                                                    value={formData.t_postcode}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button className="nc-save-btn" onClick={(e) => { e.preventDefault(); handleSaveTenantAddress(); }}>Save</button>
                                            </div>
                                            </>
                                        )}

                                        {showAdditionalTenant && (
                                            <>
                                            <h3>Additional Tenant</h3>
                                            <div className="form-group">
                                                <label htmlFor="addtent_title">Title:</label>
                                                <select 
                                                    id="addtent_title" 
                                                    name="addtent_title" 
                                                    value={formData.addtent_title} 
                                                    onChange={handleInputChange} 
                                                >
                                                    <option value="">Select Client Type</option>
                                                    <option value="Mr">Mr</option>
                                                    <option value="Mrs">Mrs</option>
                                                    <option value="Ms">Ms</option>
                                                    <option value="Miss">Miss</option>
                                                    <option value="Master">Master</option>
                                                    <option value="Dr">Dr</option>
                                                    <option value="Prof">Prof</option>
                                                    <option value="Rev">Rev</option>
                                                    <option value="Hon">Hon</option>
                                                    <option value="Sir">Sir</option>
                                                    <option value="Lady">Lady</option>
                                                    <option value="Lord">Lord</option>
                                                    <option value="Mx">Mx</option>
                                                    <option value="Col">Col</option>
                                                    <option value="Maj">Maj</option>
                                                    <option value="Capt">Capt</option>
                                                    <option value="Cmdr">Cmdr</option>
                                                    <option value="Lt">Lt</option>
                                                    <option value="Lt Col">Lt Col</option>
                                                    <option value="Cpl">Cpl</option>
                                                    <option value="Pvt">Pvt</option>
                                                    <option value="Judge">Judge</option>
                                                    <option value="Justice">Justice</option>
                                                    <option value="Amb">Amb</option>
                                                    <option value="Tsgt">Tsgt</option>
                                                    <option value="Sgt">Sgt</option>
                                                    <option value="Cpt">Cpt</option>
                                                    <option value="Ens">Ens</option>
                                                    <option value="Adm">Adm</option>
                                                    <option value="Vice Adm">Vice Adm</option>
                                                    <option value="Spc">Spc</option>
                                                    <option value="Pfc">Pfc</option>
                                                    <option value="Cdr">Cdr</option>
                                                    <option value="Gen">Gen</option>
                                                    <option value="Gov">Gov</option>
                                                    <option value="Pres">Pres</option>
                                                </select>
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_given_name">Given Name:</label>
                                                <input 
                                                    type="text" 
                                                    id="addtent_given_name" 
                                                    name="addtent_given_name" 
                                                    value={formData.addtent_given_name}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_surname">Surname:</label>
                                                <input 
                                                    type="text" 
                                                    id="addtent_surname" 
                                                    name="addtent_surname" 
                                                    value={formData.addtent_surname}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_email">Email:</label>
                                                <input 
                                                    type="email" 
                                                    id="addtent_email" 
                                                    name="addtent_email"
                                                    value={formData.addtent_email}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="addtent_contact_number">Contact Number:</label>
                                                <input 
                                                    type="text" 
                                                    id="addtent_contact_number" 
                                                    name="addtent_contact_number"
                                                    value={formData.addtent_contact_number}
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <button className="nc-save-btn" onClick={(e) => { e.preventDefault(); handleSaveAdditionalTenant(); }}>Save</button>
                                            </div>
                                            </>
                                        )}
                                    <div className="submit-button-container">
                                        <button type="submit" className="submit-button">Submit</button>
                                    </div>
                                </form>
                            </div>

                        </div>    
                    </div>
                </div>
            </div>
            <DashboardFooter />
        </div>
    );
}

export default NewClient;
